<template>
  <div>
    <!-- DESKTOP -->
    <div class="document-index__desktop pl-8 pr-8 mr-3 ml-3" v-if="!isMobile">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="document__title">Documentos corporativos</h1>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col class="pl-0" cols="6">
              <p class="mt-3 text-left text-my-document-title v-step-29">
                Documentos nuevos por firmar
              </p>
              <v-col cols="12" class="pt-0">
                <v-row class="document__container-pending">
                  <template v-if="listDocumentP.length == 0">
                    <v-alert
                      dense
                      text
                      type="info"
                      class="mt-3 document-index__alert-pending"
                      style="height: 40px; width: 100%"
                    >
                      <b> No se encontraron documentos por firmar </b>
                    </v-alert>
                  </template>
                  <template v-else>
                    <v-col
                      cols="4"
                      v-for="data in listDocumentP"
                      :key="data.id"
                      class="pt-1"
                    >
                      <v-card
                        width="270"
                        class="mx-2 mt-0 mb-0 document__hover-card"
                        :input-value="active"
                        style="border-radius: 15px !important"
                        depressed
                        rounded
                        elevation="0"
                        outlined
                      >
                        <v-card-title>
                          <v-layout justify-center>
                            <img width="100" :src="icon" />
                          </v-layout>
                        </v-card-title>
                        <v-card-text
                          style="height: 60px"
                          class="d-flex justify-center"
                        >
                          <p class="text-my-document d-flex justify-center">
                            {{
                              firstLetter(
                                data.document_sign.nombre.toLowerCase()
                              )
                            }}
                          </p>
                        </v-card-text>
                        <v-card-actions class="pt-0 mt-3 mb-3">
                          <v-hover v-slot="{ hover }">
                            <v-btn
                              @click="App(data)"
                              block
                              rounded
                              style="color: #fff"
                              :style="{
                                'background-color': hover
                                  ? '#324CA1'
                                  : '#466BE3',
                              }"
                            >
                              FIRMAR
                            </v-btn>
                          </v-hover>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-col>
            <v-col class="pr-0" cols="6">
              <v-col cols="12">
                <v-row class="pl-2">
                  <v-col cols="6" class="pa-0">
                    <p class="mt-3 text-left text-my-document-title v-step-30">
                      Documentos firmados 
                    </p>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <v-text-field v-model="search" label="Buscar">
                      <template v-slot:prepend-inner>
                        <v-icon color="#afb0b0"> mdi-magnify </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-data-table
                  :search="search"
                  :loading="loading.table"
                  :items-per-page="10"
                  :headers="headers"
                  :items="listSign"
                  :footer-props="{ itemsPerPageOptions: [5, 10] }"
                  class="elevation-1 document__table"
                >
                  <template v-slot:no-results>
                    <v-alert
                      text
                      dense
                      type="info"
                      class="mt-3 document-index__alert-pending"
                    >
                      <b>No se encontraron resultados.</b>
                    </v-alert>
                  </template>
                  <template class="pl-0 pr-0" v-slot:no-data>
                    <v-alert
                      text
                      dense
                      type="info"
                      class="mt-3 document-index__alert-pending"
                    >
                      <b>No se encontró información.</b>
                    </v-alert>
                  </template>
                  <template v-slot:progress>
                    <v-progress-linear
                      color="#214484"
                      :height="5"
                      indeterminate
                      stream
                    ></v-progress-linear>
                  </template>
                  <template v-slot:item.documentName="{ item }">
                    <div>
                      <p class="mb-0 mt-2 text-1-1-lines">
                        {{ firstLetter(item.documentName.toLowerCase()) }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:item.publicationDate="{ item }">
                    <div>
                      <p class="mb-0 mt-2 text-1-1-lines">
                        {{ item.publicationDate }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:item.signatureDate="{ item }">
                    <div>
                      <p class="mb-0 mt-2 text-1-1-lines">
                        {{ item.signatureDate }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <template
                      v-if="
                        item.documentName ===
                        'Memorando de transitoriedad en casa'
                      "
                    >
                      <p class="mb-0">Firmado desde la APP</p>
                    </template>
                    <template v-else>
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          block
                          rounded
                          small
                          style="color: #fff"
                          :style="{
                            'background-color': hover ? '#324CA1' : '#466BE3',
                          }"
                          @click="open(item)"
                          :disabled="!item.canView"
                        >
                          {{ item.canView ? 'Ver' : 'En proceso' }}
                        </v-btn>
                      </v-hover>
                    </template>
                  </template>
                </v-data-table>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="container mobile-action pb-16" v-else>
      <div class="container-greeting">
        <div class="mt-3" style="text-align: initial">
          <p class="greeting text-title-1 text-left">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img
                width="15"
                class="mt-1"
                src="../../assets/icon/navigate_before.png"
              />
            </v-btn>
            {{ name }}, acá puede encontrar
          </p>
          <p class="greeting-requests pl-9">Sus documentos corporativos</p>
        </div>
      </div>

      <div class="container-card">
        <p class="text-title-sm-d pt-6 pb-3 pl-0 mb-0 v-step-mobile-29">
          Documentos nuevos por firmar
        </p>
        <template v-if="listDocumentP && listDocumentP.length == 0">
          <v-alert
            dense
            text
            type="info"
            class="mt-3 document-index__alert-pending"
          >
            No se encontraron documentos por firmar
          </v-alert>
        </template>
        <v-row>
          <v-col cols="12">
            <v-card
              v-for="data in listDocumentP"
              :key="data.id"
              class="requests-card-document document-index__v-card-mobile"
            >
              <div class="card-content">
                <v-img
                  width="60"
                  :src="icon"
                  class="mx-auto"
                ></v-img>
                <p class="text-today-sm">
                  {{ firstLetter(data.document_sign.nombre.toLowerCase()) }}
                </p>
                <v-btn
                  small
                  rounded
                  dark
                  @click="App(data)"
                  class="btn-main"
                >
                  FIRMAR
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <p class="text-title-sm-d pt-3 pb-3 pl-0 mb-0 v-step-mobile-30">
          Documentos firmados
        </p>

        <!-- Documentos firmados -->
        <template v-if="items && listSign.length == 0">
          <v-alert
            dense
            text
            type="info"
            class="mt-3 document-index__alert-pending"
          >
            No se encontraron documentos firmados con anterioridad
          </v-alert>
        </template>

        <v-row>
          <v-col cols="12">
            <v-card
              v-for="data in listSign"
              :key="data.id"
              class="requests-card-document pb-1 pt-1 document-index__v-card-mobile"
            >
              <v-col
                style="text-align: initial"
                class="pl-2 pr-0 d-flex align-center"
                cols="2"
              >
                <v-img width="60" :src="icon"></v-img>
              </v-col>
              <v-col
                style="text-align: initial"
                cols="6"
                class="d-flex align-center"
              >
                <p class="text-today-sm pb-0 mb-0 pt-0">
                  {{ firstLetter(data.documentName.toLowerCase()) }}
                </p>
              </v-col>
              <v-col
                v-if="data.document_sign_id != 1"
                cols="4"
                class="d-flex align-center justify-center"
              >
                <v-btn
                  small
                  rounded
                  dark
                  @click="open(data)"
                  class="btn-main mt-1"
                >ver</v-btn>
              </v-col>
              <v-col
                v-else
                cols="4"
                class="d-flex align-center justify-center"
              >
                <p class="text-today-sm-1 pb-0 mb-0 pt-3">
                  <strong> Firmado desde la app </strong>
                </p>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- DIALOG -->

    <v-dialog max-width="500" v-model="dialog.newPassword" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center>
            AGREGAR CONTRASEÑA NUEVA PARA LOS DOCUMENTO
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0" style="text-align: initial">
            * Esta contraseña será usada para otros documentos que requieran una
            mayor seguridad.
          </p>
          <p class="mb-0" style="text-align: initial">
            * Si es la primera vez que ingresa, recuerde que la contraseña es su
            número de documento, para conrinuar actualice su contraseña.
          </p>
          <v-form ref="form">
            <v-text-field
              color="#466be3"
              v-model="formPass.password_current"
              label="Contraseña actual"
              @click:append="show2 = !show2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>
            <v-text-field
              color="#466be3"
              v-model="formPass.password"
              label="Nueva contraseña"
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>
            <v-text-field
              color="#466be3"
              v-model="formPass.confirmation_password"
              label="Confirmar nueva contraseña"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                style="background-color: #fff"
                color="#466be3"
                outlined
                rounded
                block
                @click="dialog.password = false"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                class="btn-main"
                dark
                rounded
                block
                @click="updatePass()"
              >
                ACTUALIZAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.password" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center> CONTRASEÑA PARA EL DOCUMENTO </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0">Este documento requiere contraseña</p>
          <v-form ref="form" @submit.prevent="validatePass(1)">
            <v-text-field
              color="#466be3"
              v-model="formPass.password"
              label="Ingrese aquí su contraseña"
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
              @keydown.enter.prevent="validatePass(1)"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                style="background-color: #fff"
                color="#466be3"
                outlined
                rounded
                block
                @click="dialog.password = false"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                @click="validatePass(1)"
                class="btn-main"
                dark
                rounded
                block
              >
                Validar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import vueShowMoreText from "vue-show-more-text";
import Alert from "../../components/Alert.vue";
import Api from "@/utils/api";
import moment from "moment";
export default {
  components: {
    vueShowMoreText,
    Alert,
  },
  data() {
    return {
      dialog: {
        password: false,
        newPassword: false,
      },
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      formPass: {
        password: "",
        confirmation_password: "",
        password_current: "",
      },
      show: false,
      show1: false,
      show2: false,
      origin: window.location.origin,
      loadingAll: false,
      listDocumentP: null,
      icon: origin + "/icon/document-commisions.svg",
      listSign: [],
      infoToken: {
        document: "",
      },
      name: "",
      tokenB64: "",
      infoDoc: null,
      search: "",
      loading: {
        table: false,
      },
      headers: [
        {
          text: "Nombre de documento",
          value: "documentName",
          align: "start",
          class: "document__header",
          sortable: false,
        },
        {
          text: "Fecha de publicación",
          value: "publicationDate",
          align: "center",
          class: "document__header",
        },
        {
          text: "Fecha de firma",
          value: "signatureDate",
          align: "center",
          class: "document__header",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "document__header",
          sortable: false,
        },
      ],
      documentFlag: true,
      isMobile: false
    };
  },
  computed: {
    documents() {
      console.log('🚀 Iniciando computed documents...');
      let getDocuments = this.$store.getters.getDocuments;
      console.log('📦 Documentos obtenidos del store:', getDocuments);
      
      // Reiniciamos las listas
      this.listSign = [];
      this.listDocumentP = [];
      
      if (getDocuments) {
        // Procesamos documentos completados
        if (getDocuments.completed && Array.isArray(getDocuments.completed)) {
          console.log('🔍 Documentos completados:', getDocuments.completed);
          console.log('📊 Cantidad de documentos completados:', getDocuments.completed.length);
          
          getDocuments.completed.forEach((document) => {
            const processedDoc = {
              ...document,
              documentName: document.document_sign.nombre,
              publicationDate: moment(document.created_at).format("L"),
              signatureDate: moment(document.updated_at).format("L"),
              canView: document.status_id === 4
            };
            
            console.log('📄 Documento procesado:', {
              id: processedDoc.id,
              nombre: processedDoc.documentName,
              fechaPublicacion: processedDoc.publicationDate,
              fechaFirma: processedDoc.signatureDate,
              status_id: document.status_id,
              canView: processedDoc.canView
            });
            this.listSign.push(processedDoc);
          });
          
          console.log('📋 Lista de documentos procesados:', this.listSign);
        }
        
        // Procesamos documentos pendientes
        if (getDocuments.pending && Array.isArray(getDocuments.pending)) {
          console.log('⏳ Documentos pendientes:', getDocuments.pending.length);
          this.listDocumentP = getDocuments.pending;
        }
        
        this.loadingAll = false;
      } else {
        console.log('❌ No hay documentos en el store');
        this.loadingAll = true;
      }
      
      return getDocuments;
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    async updateDocumentList() {
      try {
        //console.log('[Index] Iniciando actualización de lista de documentos');
        
        if (!this.tokenB64) {
          //console.error('[Index] No hay token disponible, obteniendo token...');
          this.getToken();
          
          if (!this.tokenB64) {
            //console.error('[Index] No se pudo obtener el token');
            this.alert = {
              show: true,
              type: "error",
              txt: "Error de autenticación. Por favor, inicie sesión nuevamente.",
              title: "Error",
              redirect: "login"
            };
            return;
          }
        }
        
        this.loadingAll = true;
        //console.log('[Index] Llamando a fetchAndUpdateDocuments del store');
        const documents = await this.$store.dispatch("fetchAndUpdateDocuments");
        //console.log('[Index] Documentos recibidos:', documents);
        
        if (!documents) {
          //console.error('[Index] No se recibieron documentos del servidor');
          this.alert = {
            show: true,
            type: "error",
            txt: "Error al cargar los documentos. Por favor, intente nuevamente.",
            title: "Error",
            redirect: ""
          };
        }
      } catch (error) {
        //console.error('[Index] Error al actualizar lista de documentos:', error);
        this.alert = {
          show: true,
          type: "error",
          txt: "Error al cargar los documentos. Por favor, intente nuevamente.",
          title: "Error",
          redirect: ""
        };
      } finally {
        this.loadingAll = false;
      }
    },
    validatePass(origin) {
      this.loadingAll = true;
      var data = {
        document_id: this.infoDoc.document_sign.id,
        document_user_id: this.infoDoc.id,
      };
      if (origin == 1) {
        data.manager_password = this.formPass.password;
      }

      Api.Auth()
        .validatePass(this.tokenB64, data)
        .then(async (res) => {
          if (res.data.cod == 0) {
            if (res.data.data.password_change_required) {
              this.dialog.newPassword = true;
            } else if (res.data.data.password_required) {
              if (res.data.data.url == null) {
                this.dialog.password = true;
              } else {
                window.open(res.data.data.url, "_blank");
                this.dialog.password = false;
                await this.updateDocumentList();
              }
            } else {
              this.dialog.password = false;
              this.dialog.newPassword = false;
              window.open(this.infoDoc.sign, "_blank");
              await this.updateDocumentList();
            }
          }
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.type = "error";
          this.alert.txt = error.response.data.message;
          this.alert.title = "¡Oh no!";
          this.alert.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    open(pdf) {
      this.infoDoc = pdf;
      this.validatePass(null);
      // window.open(pdf.sign, "_blank");
    },
    App(item) {
      this.$router.push({
        name: "documentDetails",
        params: { id: item.document_sign_id, docUser: item.id },
      });
    },
    getToken() {
      //console.log('[Index] Iniciando getToken');
      let token = localStorage.getItem("token");
      this.tokenB64 = localStorage.getItem("tokenB64");
      //console.log('[Index] Tokens obtenidos:', {
      //  token: token ? 'Presente' : 'Ausente',
      //  tokenB64: this.tokenB64 ? 'Presente' : 'Ausente'
      //});
      
      let div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        let nameList = this.infoToken.fullName.split(" ");
        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = this.toTitleCase(nameList[0]);
          } else {
            this.name = this.toTitleCase(nameList[1]);
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
      //console.log('[Index] Token procesado, nombre obtenido:', this.name);
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    firstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async assignDocument() {
      try {
        this.loadingAll = true;
        
        if (!this.selectedUsers.length) return;

        const tokenB64 = localStorage.getItem("tokenB64");
        if (!tokenB64) return;

        let response;
        
        if (this.formDocument.is_multifirmas === 'true') {
          const data = {
            doc_id: parseInt(this.documentId),
            users: this.selectedUsers.map(user => ({
              dsu_user_id: parseInt(user.id),
              nombre: user.full_name,
              cargo: user.profile?.name || 'Sin cargo'
            }))
          };
          
          response = await Api.documentosMultiFirmas().asignarDocumento(tokenB64, data);
        } else {
          const data = {
            documentSignId: parseInt(this.documentId),
            people: this.selectedUsers.map(user => ({
              documentNumber: user.document_number
            }))
          };

          response = await Api.Auth().createUsersDocumentsToSign(this.token, data);
        }

        if (response && (response.message || (response.data && response.data.cod === 0))) {
          await this.updateDocumentList();
          await this.$nextTick();
          this.$router.push({ name: this.config.redirectRoute });
        } else {
          throw new Error('Error al asignar el documento: Respuesta inválida del servidor');
        }
      } catch (error) {
        console.error('Error al asignar documento:', error);
        this.alert = {
          show: true,
          text: error.message || 'Error al asignar el documento',
          type: 'error'
        };
      } finally {
        this.loadingAll = false;
      }
    },
  },
  created() {
    //console.log('[Index] Iniciando created hook');
    this.getToken();
    //console.log('[Index] Token obtenido:', this.tokenB64 ? 'Presente' : 'Ausente');
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
    //console.log('[Index] Llamando a updateDocumentList desde created');
    this.updateDocumentList();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  watch: {
    documents: function (newCount, oldCount) {
      //console.log("[Index] Cambio detectado en documentos");
    },
    '$route': {
      immediate: true,
      handler(to, from) {
        //console.log('[Index] Cambio de ruta detectado:', { from: from?.name, to: to?.name });
        // Si venimos de la ruta de creación de documentos, actualizamos la lista
        if (from && (from.name === 'documentManager' || from.name === 'documentLegal')) {
          //console.log('[Index] Viniendo de creación de documentos, actualizando lista');
          this.updateDocumentList();
        }
      }
    }
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";


.navidad-text{
  display: flex;
}

.mdi-chevron-right-circle::before {
  content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle::before {
  content: "\F0B28";
  color: #466be3;
}

.text-today-sm-1 {
  font-size: 13px;
  color: #989898 !important;
}
.text-my-document {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: bold;
  margin-bottom: 0px !important;
  line-height: 28px;
  font-size: 22px;
  height: 60px;
  color: #353535;
  overflow: hidden;
}
.text-my-document-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}

.container {
  padding-bottom: 50px;
}

.container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.container-text {
  width: 50%;
}

.requests-card-document {
  margin-top: 20px;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

.title-requests {
  font-family: "RobotoRegular";
  text-align: left;
  font-size: 20px;
}

.text-requests {
  font-family: "RobotoMedium";
  text-align: left;
}

.select-bottom {
  width: 100%;
  font-weight: bold;
  font-size: 11px !important;
  padding: 0 !important;
  font-family: "RobotoRegular";
  background-color: #466be3 !important;
  color: #ffffff !important;
  border-radius: 50px;
  box-shadow: 0px 2px 8px rgba(50, 76, 161, 0.5),
    0px 4px 24px rgba(50, 76, 161, 0.3);
}

.container-greeting {
  display: flex;
}

.navigate-before {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: flex-start;
}

.greeting {
  color: #466be3;
  font-family: "RobotoRegular";
  margin: 0 !important;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
}

.greeting-requests {
  font-family: "RobotoRegular";
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin: 0 !important;
  text-align: left;
  color: #626262;
}

.container-image-before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.banner {
  display: none;
}

.title {
  display: none;
}

.description-action {
  color: #898989;
  font-family: "RobotoMedium";
  font-weight: 400;
}

.text-title-sm-d {
  text-align: left;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #466be3;
  font-family: "RobotoRegular";
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  min-width: 28px !important;
  flex: 0 1 24px !important;
}

.document-index__alert-pending {
  width: 100%;
}

.document__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}
// <!-- .navidad-style{
//   background-image: url(../../assets/img/navidad/border-navidad1.png);
//   background-repeat: round;
//   background-position-y: 2px;
// } -->
.document__hover-card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.document__table {
  height: 600px;
  position: relative;
}

.document__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.document__header {
  color: #353535 !important;
}

tr,
th,
td,
span .document__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
  max-width: 200px;
}

.document__table .v-data-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.document__container-pending {
  margin-left: -30px;
  height: 600px;
}

@media (max-width: 959px) {
  .requests-card-document {
    height: 300px;
    
    .card-content {
      gap: 15px;
      
      .v-img {
        width: 80px !important;
        height: 80px !important;
      }
    }
    
    .text-today-sm {
      font-size: 1.2rem;
    }
    
    .btn-main {
      min-width: 130px;
      height: 40px;
      font-size: 1rem;
    }
  }
}

@media (max-width: 599px) {
  .requests-card-document {
    height: 280px;
    padding: 20px;
    
    .card-content {
      gap: 15px;
      
      .v-img {
        width: 75px !important;
        height: 75px !important;
      }
    }
  }
}

@media only screen and (min-width: 260px) {
  .mobile-action {
    display: block;
  }

  .document-index__desktop {
    display: none;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 320px) {
  .container-image-before {
    width: 30%;
  }

  .container-image {
    padding: 10px;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 425px) {
  .container-image-before {
    width: 20%;
  }

  .requests-card-document {
    width: 85%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 550px) {
  .requests-card-document {
    width: 70%;
  }

  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 600px) {
  .requests-card-document {
    width: 60%;
  }
  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 768px) {
  .container-greeting {
    display: block;
  }

  .title {
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    font-family: "RobotoRegular";
    font-weight: 300;
    font-size: 30px;
    line-height: 37px;
    color: #353535;
  }

  .container-card {
    width: 93%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .requests-card-document {
    margin: auto;
    width: 47%;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .banner-first-title {
    margin-bottom: 0;
    color: #ffffff;
    font-family: "RobotoMedium";
    font-weight: 300;
    font-size: 20px;
  }

  .banner-second-title {
    margin-bottom: 0;
    color: #ffffff;
    font-family: "RobotoRegular";
    font-weight: 300;
    font-size: 30px;
  }

  .title-requests {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .text-requests {
    height: 110px;
  }

  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-action {
    display: none;
  }

  .document-index__desktop {
    display: block;
  }

  .container {
    max-width: 100%;
    padding-top: 30px;
  }

  .banner {
    height: 200px;
  }

  .container-card {
    width: 90%;
  }

  .requests-card-document {
    width: 23%;
    height: 360px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
  }

  .container-image {
    width: 100%;
    height: 130px;
  }

  .container-text {
    width: 100%;
  }

  .title-requests {
    font-size: 19px;
    height: 30px;
  }

  .text-requests {
    height: 110px;
  }

  .text-today-sm {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #466be3;
  }
}
</style>
